import React from "react";
import "./Contact.scss";

const Contact = () => {
  const handleClick = () => {
    window.location.href = "mailto:martin-philip@outlook.com";
  };

  return (
    <section id="contact">
      <div className="divider">
        <span></span>
        <span className="middle-dot"></span>
        <span></span>
      </div>
      <div className="contact-container">
        <button onClick={handleClick} className="contact-button">
          Me Contacter
        </button>
      </div>
    </section>
  );
};

export default Contact;
