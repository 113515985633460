import React, { Component } from "react";
import { Modal } from "react-bootstrap";

class ProjectDetailsModal extends Component {
  render() {
    if (!this.props.data) return null;

    const { title, description } = this.props.data;

    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-inside"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="project-description"
            style={{
              fontSize: "1.1rem",
              lineHeight: "1.6",
              color: "#333",
              padding: "20px",
            }}
          >
            {description}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ProjectDetailsModal;
