import React, { Component } from "react";

class Skills extends Component {
  render() {
    if (this.props.sharedSkills && this.props.resumeBasicInfo) {
      var sectionName = this.props.resumeBasicInfo.section_name.skills;
      var skills = this.props.sharedSkills.icons.map(function (skills, i) {
        const commonTextStyle = {
          fontSize: "12px",
          marginTop: "8px",
          fontFamily: "Roboto",
          color: "white",
          margin: "8px 0 0 0",
        };

        const commonContainerStyle = {
          width: "60px",
          height: "60px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
        };

        return (
          <li
            className="list-inline-item mx-3"
            key={i}
            style={{
              transition: "transform 0.2s ease",
              cursor: "pointer",
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.transform = "scale(1.2)")
            }
            onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
          >
            <div className="text-center skills-tile" style={{ width: "100%" }}>
              {skills.image ? (
                <div className="custom-icon" style={commonContainerStyle}>
                  <img
                    src={skills.image}
                    alt={skills.name}
                    style={{
                      width: "32px",
                      height: "32px",
                      objectFit: "contain",
                      display: "block",
                    }}
                  />
                  <p className="text-center" style={commonTextStyle}>
                    {skills.name}
                  </p>
                </div>
              ) : (
                <div style={commonContainerStyle}>
                  <i
                    className={skills.class}
                    style={{
                      fontSize: "32px",
                      display: "block",
                      textAlign: "center",
                      width: "100%",
                    }}
                  />
                  <p className="text-center" style={commonTextStyle}>
                    {skills.name}
                  </p>
                </div>
              )}
            </div>
          </li>
        );
      });
    }

    return (
      <section id="skills">
        <div className="col-md-12">
          <div className="col-md-12">
            <h1 className="section-title">
              <span className="text-white">{sectionName}</span>
            </h1>
          </div>
          <div className="col-md-12 text-center">
            <ul className="list-inline mx-auto skill-icon">{skills}</ul>
          </div>
        </div>
      </section>
    );
  }
}

export default Skills;
